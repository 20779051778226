<template>
  <div class="editPhone">
    <div class="phone-title">
      <div class="phone-img">
        <!-- <img src="../../../assets/user/loginFood.png" /> -->
      </div>
      <div class="phone-text">
        <span>注销账户</span>
      </div>
    </div>
    <div class="phone-form">
      <form>
        <p>手机号码</p>
        <input type="text" placeholder="请输入手机号码" v-model="form.phone" onkeyup="value=value.replace(/[^\d]/g,'')"
          maxlength="11" readonly="readonly" />
        <p>验证码</p>
        <div class="item">
          <input type="text" placeholder="请输入验证码" v-model="form.code" autocomplete="one-time-code"
            onkeyup="value=value.replace(/[^\d]/g,'')" maxlength="6" />
          <div class="mainColor">
            <span v-if="num > 0 && num !== 120">{{ num }}秒后可重新获取</span>
            <span v-else @click="getCode()">获取验证码</span>
          </div>
        </div>
      </form>
    </div>
    <div class="phone-button">
      <div class="submitBut" @click="onSubmit()">提交</div>
    </div>

  </div>
</template>

<script>
import { getToken } from '../../../api'
import { mapState, mapActions } from 'vuex'
import { Dialog } from 'vant';

export default {
  name: 'cancellation',
  data() {
    return {
      form: {
        phone: '',
        code: '',
      },
      num: 120,
      phone: '',
      agentid: localStorage.getItem('agent'),
    }
  },
  computed: {
    ...mapState('user', ['custMsg']),
  },
  created() {
    this.getTime()

    this.form.phone = this.custMsg.phone
    this.phone = this.custMsg.phone
  },
  methods: {
    ...mapActions('user', ['getUserInfo']),

    getCode() {
      if (this.form.phone === '' || this.form.phone.length < 11) {
        this.$toast('请输入完整的电话号码')
        return
      }

      getToken({
        trxCode: 'XF172',
        phone: this.form.phone,
        agentId: this.agentid,
      }).then((res) => {
        if (res.trxCode) {
          localStorage.setItem('time', Date.parse(new Date()) / 1000)
          this.$toast('已发送')
          this.getTime()
        } else {
          this.$toast(res.rspMsg)
        }
      })
    },
    getTime() {
      var date2 = Date.parse(new Date()) / 1000
      var time = date2 - localStorage.getItem('time')
      this.num = 120 - time
      if (this.num > 0) {
        setTimeout(this.getTime, 1000)
      }
    },
    onSubmit() {
      const bodyClass = document.body.className
      let theme = this.themesMap.find(v => v.value === bodyClass)
      Dialog.confirm({
        title: '提示',
        message: '确定注销改账户? 注销后将无法继续登录!',
        confirmButtonColor: theme.color,
        width: '80vw'
      })
        .then(() => {
          getToken({
            trxCode: 'XF178',
            phone: this.form.phone,
            code: this.form.code,
          }).then((res) => {
            if (res.trxCode) {
              this.$toast('注销成功')
              localStorage.removeItem('H5token')
              localStorage.removeItem('loginType')
              this.$router.push('/')
            } else {
              this.$toast(res.rspMsg)
            }
          })
        })
        .catch(() => {
          // on cancel
        });

    },
  },
}
</script>

<style scoped lang="less">
@import url('../../../styles/phone.less');
.editPhone {
  width: 100%;
  height: 100%;
}
</style>
